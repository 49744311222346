 <template>
  <div>
    <home-login/>
  </div>
</template>

<script>
import Login from '@/components/Auth/Login.vue'
export default {
  name: 'Login',
  components: {
    'home-login': Login
  }
}
</script>

<style lang="scss" scoped>
    p{
      color: #000;
    }
</style>
